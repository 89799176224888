.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #299d70;
    color: white;
    padding: 20px;
}

.logo img {
    width: 50px;
    height: 50px;
}

.menu {
    width: 100px;
    color: #f1f1f1;
    
    position: fixed;
    height: 100vh;
  
}

.menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.menu li {
    margin-bottom: 20px;
}

.menu a {
    color: white;
    text-decoration: none;
}

.menu a:hover {
    color: #e5ebe8;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

button {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.addNewUser .input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 2px solid rgb(19, 19, 19);
  
  

   
}